import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import SEO from "../components/seo";
import Skel from "../components/skel";

const Subfeature = ({ headline, description, icon = "fa-bolt" }) => {
  return (
    <div className="media" style={{ marginBottom: "1em" }}>
      <div className="icon-60 gray-bg">
        <i className={`fas ${icon}`}></i>
      </div>
      <div className="media-body p-20px-l">
        <h6>{headline}</h6>
        <p className="m-0px">{description}</p>
      </div>
    </div>
  );
};

const Feature = ({ headline, description, subfeatures }) => {
  return (
    <div className="m-15px-tb" style={{ marginBottom: "2em" }}>
      <div className="h3 m-15px-b">{headline}</div>
      <p className="font-1 m-20px-b">{description}</p>
      {subfeatures}
    </div>
  );
};

const FeaturesPage = () => (
  <Skel>
    <SEO title="Features of Taplist.io Digital Beer Menu System" />
    <section
      className="section bg-center bg-cover effect-section"
      style={{ paddingTop: "50px", paddingBottom: "50px" }}
    >
      <div className="mask theme-bg opacity-8"></div>
      <Container>
        <Row>
          <Col lg={12} className="text-center">
            <h2 className="white-color h1 m-20px-b">Features of Taplist.io</h2>
          </Col>
        </Row>
      </Container>
    </section>
    <main>
      <Container className="p-3">
        <Row style={{ marginTop: "2em" }}>
          <Col md={{ span: 8, offset: 2 }}>
            <Feature
              headline="Instantly Updating TV Menu App"
              description={
                <>
                  Taplist.io is the world's first system built from the ground
                  up as an awesome digital TV menu system. To do this, we
                  created a rock-solid <b>TV app</b> that works on the most
                  popular TV devices.
                </>
              }
              subfeatures={[
                <Subfeature
                  headline="Instant updates"
                  icon="fa-bolt"
                  description={
                    <>
                      Changes to your menu are pushed instantly to your TVs,
                      thanks to our lightning-fast push gateway infrastructure.
                    </>
                  }
                />,
                <Subfeature
                  headline="Low-cost hardware"
                  icon="fa-plug"
                  description={
                    <>
                      Unlike other options, Taplist.io works with low-cost and
                      widely-available hardware devices. You don't need to buy
                      expensive, specialized, hardware.
                    </>
                  }
                />,
                <Subfeature
                  headline="Proven reliability"
                  icon="fa-check-circle"
                  description={
                    <>
                      Our app and infrastructure is rock-solid. Taplist.io
                      displays have <b>over one million hours</b> of crash-free
                      operational reliability in real-world use.
                    </>
                  }
                />,
              ]}
            />

            <Feature
              headline="Hosted Web &amp; QR Code Menu"
              description={
                <>
                  Every account comes with its own web address and web menu,
                  with a built-in QR Code generator.
                  Share and show off your menu with your own "permalink"
                  address, and leave the hosting to us.
                </>
              }
              subfeatures={[
                <Subfeature
                  headline="Clean, customizable look"
                  icon="fa-cogs"
                  description={
                    <>
                      Unlike other products, we are not trying to pack in a bunch
                      of social features. Your menu page shows <em>just</em> your menu, no
                      other crap &mdash; and with a clean, professional look you
                      can easily customize.
                    </>
                  }
                />,
                <Subfeature
                  headline="Embed support"
                  icon="fa-code"
                  description={
                    <>
                      You can embed your menu in other sites and systems, like
                      WordPress or your own web site. Your web developer will
                      love our simple HTML/CSS customization options.
                    </>
                  }
                />,
                <Subfeature
                  headline="Custom HTTPS site"
                  icon="fa-lock"
                  description={
                    <>
                      Show off your menu on the web with a simple and
                      straightforward web address. We automatically build a hosted
                      https site for your menu.
                    </>
                  }
                />,
              ]}
            />

            <Feature
              headline="Web-Based Management Dashboard"
              description={
                <>
                  To manage your menu, there is no special app to download. You
                  or your team simply{" "}
                  <a href="https://app.taplist.io/">
                    log in to the Taplist.io dashboard
                  </a>{" "}
                  to manage all aspects of your account.
                </>
              }
              subfeatures={[
                <Subfeature
                  headline="Mobile-friendly dashboard"
                  icon="fa-mobile-alt"
                  description={
                    <>
                      Our management dashboard works great on Android and iOS in the
                      web browser. You can add kegs, update prices, and perform
                      all management tasks, from any mobile device.
                    </>
                  }
                />,
                <Subfeature
                  headline="Manage from anywhere"
                  icon="fa-globe"
                  description={
                    <>
                      With Taplist.io, management of your menu is <em>in the cloud</em>.
                      You can access the Taplist.io dashboard from anywhere with
                      an internet connection.
                    </>
                  }
                />,
                <Subfeature
                  headline="Fast, modern design"
                  icon="fa-cube"
                  description={<>Our dashboard is fast and snappy. Try it, you'll see!</>}
                />,
              ]}
            />

            <Feature
              headline="Highly Customizable, No Designer Required"
              description={
                <>
                  No two brews are alike, and the same is true for your menus.
                  It is very easy to customize the look of your menus with
                  Taplist.io.
                </>
              }
              subfeatures={[
                <Subfeature
                  headline="HD background images"
                  icon="fa-images"
                  description={
                    <>
                      Use our built-in library of rich, stylish background
                      images, or upload your own.
                    </>
                  }
                />,
                <Subfeature
                  headline="Layout and color customizations"
                  icon="fa-align-center"
                  description={
                    <>
                      Our theme library supports many easy-to-use
                      customizations, like changing the fonts, text colors, and
                      general layout.
                    </>
                  }
                />,
              ]}
            />

            <Feature
              headline="Private Beverage Database"
              icon="fa-edit"
              description={
                <>
                  Every account has its own <b>private</b> beverage database:
                  You set the description and other beverage details.
                </>
              }
              subfeatures={[
                <Subfeature
                  headline="Advanced metadata"
                  icon="fa-chart-line"
                  description={
                    <>Set ABV, IBU, OG, FG, SRM, and many other fields.</>
                  }
                />,
                <Subfeature
                  headline="Supports coffee, wine, cider, and more"
                  icon="fa-coffee"
                  description={
                    <>
                      We support numerous beverage types, including coffee,
                      wine, cider, and more.
                    </>
                  }
                />,
              ]}
            />

            <Feature
              headline="High-Quality Built-In Illustrations"
              icon="fa-edit"
              description={
                <>
                  We believe you shouldn't need an expensive art department just
                  to make your menu look good. That's why we created a
                  high-quality library of built-in illustrations to match your
                  menu.
                </>
              }
              subfeatures={[
                <Subfeature
                  headline="Keg shell illustrations"
                  icon="fa-paint-brush"
                  description={
                    <>
                      Our built-in keg images automatically adjust to your
                      beverage's color.
                    </>
                  }
                />,
                <Subfeature
                  headline="Over 15+ glassware types"
                  icon="fa-glass-martini"
                  description={
                    <>
                      Achieve a fancy look in minutes, by showing the right
                      glassware next to each beverage. Like our keg
                      illustrations, glassware will show whatever color your
                      select.
                    </>
                  }
                />,
              ]}
            />

            <Feature
              headline="Professional Features"
              description={
                <>
                  Busy bar/restaurant? We designed Taplist.io to do its job and
                  get out of your way. Our pro-quality features were built after
                  years of battle-testing.
                </>
              }
              subfeatures={[
                <Subfeature
                  headline="Price and serving size management"
                  icon="fa-beer"
                  description={
                    <>
                      Pro accounts can set prices for beverages. Create multiple
                      serving sizes (draft, growler, pitcher, and so on) and set
                      different prices for each.
                    </>
                  }
                />,
                <Subfeature
                  headline="Team mode"
                  icon="fa-users"
                  description={
                    <>
                      Account owners can invite additional team members, such as
                      bar/brewery staff. These users can manage your menu, but
                      cannot access your billing details.
                    </>
                  }
                />,
                <Subfeature
                  headline="Multi-Location"
                  icon="fa-map"
                  description={
                    <>
                      Is your business growing? Taplist.io is ready to grow with you:
                      You can set up and manage additional locations with the click of
                      a button, all from the same account.
                    </>
                  }
                />,
              ]}
            />

            <Feature
              headline="Easy To Use"
              description={
                <>
                  Almost all our customers build and install their system
                  without any help from us. That's not an accident: We designed
                  Taplist.io to be the kind of product{" "}
                  <em>we would want to use in our own bar</em>.
                </>
              }
              subfeatures={[
                <Subfeature
                  headline="Flexible, no-hassle payment options"
                  icon="fa-credit-card"
                  description={
                    <>
                      We support both month-to-month and annual payment options,
                      and we use a secure payment card processor. You don't need
                      to call us to get started.
                    </>
                  }
                />,
                <Subfeature
                  headline="Easy cancellations"
                  icon="fa-smile"
                  description={
                    <>
                      We're so confident in our product, we make it easy to
                      cancel it when it's time to move on. We stand by our
                      simple, no questions asked{" "}
                      <a href="/refund-policy">refund policy</a>.
                    </>
                  }
                />,
              ]}
            />
          </Col>
        </Row>
      </Container>
    </main>
  </Skel>
);

export default FeaturesPage;
